import React from 'react';
import Layout from '../components/Layout';
import styled from 'styled-components';
import SEO from '../components/SEO/SEO';

const AboutMe = styled.section`
  padding: 0 1rem;
`;

const SkillsContainer = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  //display: grid;
`;

const SkillsList = styled.ul`
  list-style: none;
  margin: 0 auto;
  max-width: 900px;
  padding: 0;
  li {
    padding: 0;
    margin: 0;
    h3 {
      padding: 1rem 1rem 0;
      margin: 0;
      text-decoration: overline;
      text-decoration-color: gold;
    }
    p {
      margin: 0;
      padding: 0.5rem 1rem;
    }
  }
`;

export default (props) => {
  return (
    <Layout>
      <SEO title='About Me' pathname='/about/' />
      <AboutMe>
        <p>Hi, I'm Josh, a designer and developer based in the South of England.</p>
        <p>
          {props.location.search === '?highlight=true'
            ? "If you'd like to hire me or work with me, you can get hold of me by emailing "
            : 'You can get hold of me by emailing '}
          {props.location.search === '?highlight=true' ? (
            <span style={{ backgroundColor: 'gold', padding: '0.2rem' }}>josh@joshlong.design</span>
          ) : (
            'josh@joshlong.design'
          )}
          ,{' '}
          <a rel='noopener noreferer' href='https://www.facebook.com/JLDesignBranding'>
            {' '}
            follow me on Facebook
          </a>{' '}
          or{' '}
          <a rel='noopener noreferer' href='https://www.linkedin.com/in/joshlongdesigner'>
            find me on LinkedIn
          </a>
          .
        </p>
      </AboutMe>
      <SkillsContainer>
        <SkillsList>
          <li>
            <h3>Brand Development</h3>
            <p>
              From branding strategies to logo design, helping clients connect with their target
              markets through visual branding in order to maintain customer loyalty, initiate market
              growth or attract new demographics of customers
            </p>
          </li>
          <li>
            <h3>Product Design</h3>
            <p>
              Design of real world products from brief to concept and on to full production,
              ensuring the design is suitable for manufacture, cost effective and fit for purpose
            </p>
          </li>

          <li>
            <h3>User centred design</h3>
            <p>
              Design is for real people. I practice empathetic user centred design, putting people
              at the forefront of design thinking means that the final result truly connects with
              users and customers and improves user experience.
            </p>
          </li>
        </SkillsList>
      </SkillsContainer>
    </Layout>
  );
};
